import React from "react";
import "./Section1.css";

function Section1() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <>
      <div className="kids-overlay">
        <div className="cancer-container">
          {/* <div className="main-title">Help a child with</div>
          <div className="color-text">CANCER</div>
          <div className="main-body">be just another kid at camp.</div> */}

          <button className="donate-btn1 donate-btn" onClick={handleClick}>
            Donate points
          </button>
        </div>
      </div>
    </>
  );
}

export default Section1;
