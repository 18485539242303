import React, {useState} from 'react';
import './Navbar.css';
import logo from '../images/logo.PNG';

function Navbar() {
    const handleClick = () => {
        window.open("https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
         "_blan");
      };
    return (
        <>
        <div className='navbar'>
            <a href="/" className='logo'>
                <img src={logo} alt = "Site Logo" />
            </a>
            <button className='nav-donate-btn' onClick={handleClick}>DONATE POINTS</button>
        </div>

        </>
    )
}

export default Navbar;