import React from "react";
import "./Section2.css";
import section2Image from "../images/pic1.png";

function Section2() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <div className="camp-container">
      <div className="camp-title">
        Help us Stuff the Bus with School Supplies
      </div>
      <div className="progress"></div>
      <div className="color-text2 color-text">
        It takes $5,000 to fill the bus.
      </div>

      <div className="flex-container">
        <div className="left-content">
          {/* <div className="q1">How do I Donate?</div> */}
          <div className="q1-image"></div>
          <div className="a1">
            Back-to-school expenses can be difficult for many San Antonio
            families. By donating some of your reward points, you help a
            deserving student get the school supplies they need.
          </div>
          <button className="donate-btn2 donate-btn" onClick={handleClick}>
            Donate points
          </button>
        </div>
        <div className="image-container">
          <img src={section2Image} alt="Section 2 Image" />
        </div>
      </div>
    </div>
  );
}

export default Section2;
