import React from 'react'
import './Footer.css';
import logo from '../images/logo.PNG';
import flogo from '../images/flogo.PNG';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutubeSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';


function Footer() {
  return (
    <>
    <div className='footer-container'>
      
      <a href="https://biobridgeglobal.org/donors/?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_stb&t&utm_content=dontate_campaign" 
      target="_blank">
        <img src={flogo} alt = "STB&T Logo" className="stbt-logo" />
      </a>
      <div className='sep'>|</div>
      <a href="/">
        <img src={logo} alt = "Site Logo" className="footer-logo" />
      </a>
      
    
      <div>
        <ul className="social-icons">
          <li>
            <a href="https://www.facebook.com/SouthTexasBloodandTissue" target="_blank">
              <FontAwesomeIcon icon={faFacebook} size="2x"/>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/SouthTexasBlood" target="_blank">
              <FontAwesomeIcon icon={faTwitter} size="2x"/>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/southtexasblood?igshid=YmMyMTA2M2Y=" target="_blank">
              <FontAwesomeIcon icon={faInstagram} size="2x"/>
            </a>
          </li>
          <li>
            <a href="https://youtube.com/@SouthTexasBlood" target="_blank">
              <FontAwesomeIcon icon={faYoutube} size="2x"/>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-text">© 2024 SOUTH TEXAS BLOOD & TISSUE</div>

    </div>


    </>
  )
}

export default Footer