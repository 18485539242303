import React from "react";
import "./Section3.css";

function Section3() {
  const campClick = () => {
    window.open("https://www.cissa.org", "_blank");
  };
  return (
    <div className="joy-container">
      <div className="joy-image"></div>
      <div className="joy-day-container">
        <div className="sec3-text-container">
          <div className="joy-title">
            About Community in Schools San Antonio
          </div>
          <div className="joy-p">
            Working directly in about 160 schools across 13 school districts,
            Communities In Schools of San Antonio builds relationships that
            empower students to stay in school and succeed in life.
          </div>
        </div>
        <button className="special-btn" onClick={campClick}>
          LEARN MORE
        </button>
      </div>
    </div>
  );
}

export default Section3;
