import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5';

// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import './App.css';

function App() {
  // useEffect(() => {
  //   ReactGA.initialize('G-43BYM66M0Q');
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  const trackingId = "G-43BYM66M0Q"; 
  ReactGA.initialize(trackingId);
  ReactGA.pageview(window.location.pathname);

  // useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
  // }, []);




  return (
    <>
      {/* <Router> */}
        <Navbar />
          {/* <Routes>
            <Route path='/' exact />
          </Routes> */}
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Footer />
      {/* </Router> */}
    </>
  );
}

export default App;
